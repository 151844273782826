/* eslint-disable */
import React, { useState } from "react";
import { Select } from 'antd';
import "./tournament.less";
const { Option } = Select;

const seeds = {
  1: "s1",
  2: "s2",
  3: "s3",
  4: "s4",
  5: "s5",
  6: "s6",
  7: "s7",
  8: "s8",
  9: "s9",
  10: "s10",
  11: "s11",
  12: "s12",
  13: "s13",
  14: "s14",
  15: "s15",
  16: "s16"
}

const initialState = {
  region: {
    round_1: {
      1: {
        1: {
          seed: '1',
          name: 'BCC',
          next: 1,
        },
        2: {
          seed: '16',
          name: 'AFP',
          next: 1,
        }
      },
      2: {
        1: {
          seed: '8',
          name: 'DCM',
          next: 1
        },
        2: {
          seed: '9',
          name: 'FFF',
          next: 1
        }
      },
      3: {
        1: {
          seed: 5,
          name: 'WHY',
          next: 2
        },
        2: {
          seed: 12,
          name: 'MOW',
          next: 2
        }
      },
      4: {
        1: {
          seed: 4,
          name: 'POW',
          next: 2
        },
        2: {
          seed: 13,
          name: 'HOW',
          next: 2
        }
      },
      5: {
        1: {
          seed: 6,
          name: 'MOO',
          next: 3
        },
        2: {
          seed: 6,
          name: 'MOO',
          next: 3
        }
      },
      6: {
        1: {
          seed: 3,
          name: 'MOO',
          next: 3
        },
        2: {
          seed: 14,
          name: 'MOO',
          next: 3
        }
      },
      7: {
        1: {
          seed: 7,
          name: 'MOO',
          next: 4
        },
        10: {
          seed: 10,
          name: 'MOO',
          next: 4
        }
      },
      8: {
        1: {
          seed: 2,
          name: 'MOO',
          next: 4
        },
        2: {
          seed: 15,
          name: 'MOO',
          next: 4
        }
      }
    },
    round_2: {
      1: {
        1: {
          next: 1
        },
        2: {
          next: 1
        }
      },
      2: {
        1: {
          next: 1
        },
        2: {
          next: 1
        }
      },
      3: {
        1: {
          next: 2
        },
        2: {
          next: 2
        }
      },
      4: {
        1: {
          next: 2
        },
        2: {
          next: 2
        }
      },
    },
    round_3: {
      1: {
        1: { next: 1 },
        2: { next: 1 }
      },
      2: {
        1: { next: 1 },
        2: { next: 1 }
      }
    },
    round_final: {
      1: {
        1: { next: 1 },
        2: { next: 1 }
      }
    },
    winner: {
      name: '',
      seed: ''
    }
  },
}

const Spacer = () => (<li className={`spacer`}>&nbsp;</li>);

const GameSpacer = () => (<li className='game game-spacer'>&nbsp;</li>);

const RegionName = ({ name }) => (<div className='region-title'>{name}</div>);

const RegionWinner = props => {
  const {
    team,
    handleTeamClick,
    orientation
  } = props;

  return (
    <li className={`game ${/top/.test(orientation) ? 'game-top' : 'game-bottom'}`}
      onClick={() => handleTeamClick(team)}>
      {team.name}
      <span>{team.name ? `50%` : '\u00A0'}</span>
    </li>
  )
}

const Team = (props) => {
  const { team,
    gameIndex,
    index,
    seed,
    children,
    handleTeamClick
  } = props;

  const nextTeamIndex = gameIndex % 2 !== 0 ? 1 : 2;
  // Placeholder for dummy values
  const predictionResult = () => {
    return Math.floor(Math.random() * 100);
  };

  return (
    <>
      <li className={`game ${index % 2 === 0 ? 'game-top' : 'game-bottom'}`}
        onClick={() => handleTeamClick({ gameNumber: team.next, teamNumber: nextTeamIndex, payload: { name: team.name } })}>
        {team.name}
        <span>{team.name ? `${predictionResult()}%` : '\u00A0'}</span>
      </li>
      {children}
    </>
  );
}

const Games = props => {
  const {
    handleTeamClick,
    regionName,
    round,
    games,
    gameIndex
  } = props;

  return (
    <>
      {Object.keys(games).map((teams, index) => (
        <Team
          team={games[teams]}
          gameIndex={gameIndex}
          index={index}
          handleTeamClick={handleTeamClick}>
          {index % 2 === 0 ? <GameSpacer /> : <Spacer />}
        </Team>
      ))}
    </>
  )
};


const Round = (props) => {
  const {
    round,
    winningTeam,
    roundNumber,
    regionName,
    handleTeamClick,
    isFinalRound,
    orientation
  } = props;

  if (isFinalRound) {
    return (
      <ul className={`round region-winner`}>
        <Spacer />
        <RegionWinner team={winningTeam} handleTeamClick={handleTeamClick} orientation={orientation}></RegionWinner>
        <Spacer />
      </ul>
    )
  }
  return (
    <ul className={`round round-${roundNumber}`}>
      <Spacer />
      {Object.keys(round).map((games, index) => (
        <Games
          key={index}
          round={roundNumber}
          games={round[games]}
          gameIndex={index + 1}
          handleTeamClick={handleTeamClick}
          regionName={regionName}>
        </Games>
      ))}
    </ul>
  );
};

const Region = (props) => {

  const {
    regionName,
    orientation,
    rounds,
    handleFinalFourClick,
  } = props;

  const {
    round_1,
    round_2,
    round_3,
    round_final,
    winner,
  } = rounds;

  const [round1, setRound1] = useState(round_1);
  const [round2, setRound2] = useState(round_2);
  const [round3, setRound3] = useState(round_3);
  const [finalRound, setFinalRound] = useState(round_final);
  const [winningTeam, setWinningTeam] = useState(winner);

  const updateTeam = (e) => {
    return (prevState) => {
      let newState = {
        ...prevState,
        [e.gameNumber]: {
          ...prevState[e.gameNumber],
          [e.teamNumber]: {
            ...prevState[e.gameNumber][e.teamNumber],
            name: e.payload.name
          }
        }
      };

      return newState;
    };
  }

  const handleRoundOneClick = (e) => {
    setRound2(updateTeam(e));
  }

  const handleRoundTwoClick = (e) => {
    setRound3(updateTeam(e));
  }

  const handleRoundThreeClick = (e) => {
    setFinalRound(updateTeam(e));
  }

  const handleFinalRoundClick = (e) => {
    setWinningTeam(e.payload);
  }

  return (
    <div className='region'>
      <div className={`rounds ${orientation}`}>
        <Round
          roundNumber={1}
          round={round1}
          handleTeamClick={handleRoundOneClick}>
        </Round>
        <Round
          roundNumber={2}
          round={round2}
          handleTeamClick={handleRoundTwoClick}>
        </Round>
        <Round
          roundNumber={3}
          round={round3}
          handleTeamClick={handleRoundThreeClick}>
        </Round>
        <Round
          roundNumber={4}
          round={finalRound}
          handleTeamClick={handleFinalRoundClick}
          regionName={regionName}>
        </Round>
        <Round
          isFinalRound={true}
          winningTeam={winningTeam}
          orientation={orientation}
          handleTeamClick={handleFinalFourClick}>
        </Round>
      </div>
    </div>
  );
};

const Tournament = () => {
  const [state, setState] = useState(initialState);
  const [finalistTop, setFinalistTop] = useState({});
  const [finalistBottom, setFinalistBottom] = useState({});
  const [champion, setChampion] = useState({});

  const {
    region
  } = state;

  const handleFinalFourLeftClick = (e) => {
    console.log(e);
    setFinalistTop(e);
  }

  const handleFinalFourRightClick = (e) => {
    setFinalistBottom(e);
  }

  const handleFinalistTopClick = (e) => {
    console.log(e);
    setChampion(e);
  }

  const handleFinalistBottomClick = (e) => {
    setChampion(e);
  }

  return (
    <div className="tournament" >
      <div className='col'>
        <Region
          regionName={"East"}
          orientation={'top-left'}
          rounds={region}
          handleFinalFourClick={handleFinalFourLeftClick}
        />
        <Region
          regionName={"West"}
          orientation={'bottom-left'}
          rounds={region}
          handleFinalFourClick={handleFinalFourLeftClick}

        />
      </div>
      <div className='col center'>
        <div className='region'>
          <div className='championship center'>
            <ul class="round">
              <li class="game championship-spacer"></li>
              <li class="game championship-spacer">
                <li class="game championship-top"
                  onClick={() => handleFinalistTopClick(finalistTop)}>
                    {finalistTop.name}
                  <span>x%</span>
                  </li>
                <li class="game game-champion">{champion.name}</li>
                <li class="game championship-bottom"
                  onClick={() => handleFinalistBottomClick(finalistBottom)}>
                  {finalistBottom.name}
                  <span>x%</span>
                  </li>
              </li>
              <li class="game championship-spacer"></li>
              </ul>
          </div>
        </div>
      </div>
      <div className='col'>
        <Region
          regionName={"South"}
          orientation={'top-right'}
          rounds={region}
          handleFinalFourClick={handleFinalFourRightClick}
        />
        <Region
          regionName={"Midwest"}
          orientation={'bottom-right'}
          rounds={region}
          handleFinalFourClick={handleFinalFourRightClick}
        />
      </div>
    </div>
  );
};

export default Tournament;
