import React from 'react';
import Layout from '../components/layout';
import Tournament from '../containers/Tournament';

const BracketAssist = () => {
  return (
    <Layout>
      <Tournament />
    </Layout>
  );
};

export default BracketAssist;

